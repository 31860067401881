<template>
  <div class="common-footer">
    <div class="footer-top-company">
      <img :src="$http + '/img/logoHeader.png'" alt="">
      <span>Beijing Longsi Feiyang Technology Co., LTD</span>
    </div>
    <div class="footer-top">
      <div class="footer-top-list" v-for="(item, index) in list" :key="index">
        <div class="footer-top-list-name">{{item.name}}</div>
        <div class="footer-top-list-info" v-for="(val, _index) in item.children" @click="toRouterIn(item)" :key="_index">
          <span>{{val.name}}</span>
        </div>
      </div>
      <div class="footer-top-scan">
        <!-- <img src="https://upload.wikimedia.org/wikipedia/commons/f/f2/%E7%BB%B4%E5%9F%BA%E4%B8%AD%E6%96%87%E7%A4%BE%E5%8A%A0%E7%BE%A4%E4%BA%8C%E7%BB%B4%E7%A0%81.png" alt=""> -->
      </div>
    </div>
    <div class="footer-under">
      <div class="footer-under-info">
        <span>@2024 Longsqin. All rights reserved.</span>
        <span @click="toBeiAn">Beijing Longsi Feiyang Technology Co.,LTD.Beijing ICP 2024043714 number-1</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      list: [
        {
          name: 'Home',
          children: [
            {
              name: 'Qin Strings'
            },
            {
              name: 'Product profile'
            },
            {
              name: 'Creation history'
            },
            {
              name: 'Recommend auditions'
            }
          ]
        },
        {
          name: 'Products',
          children: [
            {
              name: 'Product details'
            },
            {
              name: 'Product differentiating Features'
            },
            {
              name: 'Product advantages'
            }
          ]
        },
        {
          name: 'About',
          children: [
            {
              name: 'Founder profile'
            },
            {
              name: 'Development history'
            },
            {
              name: 'What’s New'
            }
          ]
        }
        ,
        {
          name: 'Download',
          children: []
        }
        ,
        {
          name: 'Help',
          children: []
        }
      ]
    }
  },
  methods: {
    toBeiAn(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
    toRouterIn(item){
      let path = ''
      switch (item.name) {
        case 'Home':
          path = '/qy/en/home'
        break;
        case 'Products':
          path = '/qy/en/detail'
        break;
        case 'About':
          path = '/qy/en/about'
        break;
      }
      if(path!=this.$route.path){
        this.$router.push({
          path: path
        })
      }
    }
  }
}
</script>

<style scoped lang='scss'>
    .common-footer{
      border-top: 1px solid #d39a4f;
      width: 100%;
      background: #000;
      color: #fff;
      display: flex;
      flex-direction: column;
      .footer-top-company{
          margin: 16px 0 34px 252px;
          display: flex;
          align-items: center;
          img{
            width: 138px;
            height: 57px;
            margin-right: 30px;
          }
          span{
            font-size: 24px;
            color: rgba(255,255,255,.6);
            line-height: 29px;
            font-family: Source-KeynoteartHans;
          }
        }
      .footer-top{
        padding: 0px 150px 0 298px;
        box-sizing: border-box;
        display: flex;
        // justify-content: space-between;
        .footer-top-list{
          text-align: left;
          width: 248px;
          .footer-top-list-name{
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 33px;
          }
          .footer-top-list-info{
            color: rgba(255,255,255,.6);
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 12px;
            cursor: pointer;
          }
          .footer-top-list-info:hover{
            color: rgba(255,255,255,1);
          }
        }
        .footer-top-scan{
          width: 120px;
          height: 120px;
          background: linear-gradient(180deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.4) 49%, rgba(255,255,255,0.5) 100%, rgba(0,0,0,0) 100%);
          border-radius: 8px;
          opacity: 0.6;
          backdrop-filter: blur(10px);
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 105px;
            height: 105px;
            border-radius: 8px;
          }
        }
        
      }
      .footer-under{
        margin-top: 26px;
        padding: 0 0 10px 0;
        font-size: 16px;
        line-height: 25px;
        color: rgba(255,255,255,.6);
        .footer-under-info{
          text-align: center;
          span{
            &:nth-of-type(1){
              margin-right: 70px;
            }
            &:nth-of-type(2){
              cursor: pointer;
            }
          }
        }
      }
    }
</style>
